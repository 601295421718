const THRESHOLD = 0.005

export class Spring {
  public target = 0
  public velocity = 0
  public animating = false

  constructor(
    public value = 0,
    public stiffness = 100,
    public damping = 20,
    public mass = 1,
  ) {
    this.target = this.value
  }

  setValue(value: number) {
    this.value = value
    this.target = value
  }

  setTarget(target: number) {
    this.target = target
  }

  update(delta: number) {
    const displacement = this.value - this.target
    const springForce = -displacement * this.stiffness
    const dampingForce = -this.velocity * this.damping

    const force = springForce + dampingForce
    this.velocity += (force / this.mass) * delta
    this.value += this.velocity * delta

    this.animating = Math.abs(this.velocity) > THRESHOLD
  }
}
