import { MathUtils, Spherical } from 'three'
import Stage, { SPRING_CONFIG } from './Stage'
import { Spring } from './Spring'
import { State } from './App'

const MIN_PHI = Math.PI * 0.4
const MAX_PHI = Math.PI * 0.6

const MAP_MIN_PHI = Math.PI * 0.2
const MAP_MAX_PHI = Math.PI * 0.4

export default class Dolly {
  private readonly spherical = new Spherical()

  private theta: Spring
  private phi: Spring
  private radius: Spring
  private x: Spring
  private y: Spring

  private thetaStart = 0
  private phiStart = Math.PI * 0.5

  public autoRotate = false
  public locked = false

  constructor(
    private readonly stage: Stage,
    state: State,
  ) {
    const { theta, phi, radius, x, y } = this.getTargets(state)

    this.theta = new Spring(theta, ...SPRING_CONFIG)
    this.phi = new Spring(phi, ...SPRING_CONFIG)
    this.radius = new Spring(radius, ...SPRING_CONFIG)
    this.x = new Spring(x, ...SPRING_CONFIG)
    this.y = new Spring(y, ...SPRING_CONFIG)

    this.setLocked(state)
    this.setAutoRotate(state)

    this.updateCamera()

    this.stage.controls.on('start-drag', this.onDragStart.bind(this))
    this.stage.controls.on('end-drag', this.onDragEnd.bind(this))
  }

  onDragStart() {
    if (this.locked) return
    this.phiStart = this.phi.value
    this.thetaStart = this.theta.value
  }

  onDragEnd() {
    if (this.locked) return

    const {
      width,
      height,
      controls: { velocity },
    } = this.stage

    const multiplier = Math.min(width, height, 800)

    const mapAmount = this.stage.planet?.mapAmount.value || 0
    const min = MathUtils.lerp(MIN_PHI, MAP_MIN_PHI, mapAmount)
    const max = MathUtils.lerp(MAX_PHI, MAP_MAX_PHI, mapAmount)

    this.theta.setTarget(this.theta.value - velocity.x * multiplier)
    this.theta.velocity = velocity.x * -multiplier

    this.phi.setTarget(MathUtils.clamp(this.phi.value - velocity.y * multiplier, min, max))
    this.phi.velocity = velocity.y * -multiplier
  }

  updateCamera() {
    this.spherical.theta = this.theta.value
    this.spherical.phi = this.phi.value
    this.spherical.radius = this.radius.value

    this.stage.camera.position.setFromSpherical(this.spherical)
    this.stage.camera.lookAt(this.stage.scene.position)

    this.stage.camera.setViewOffset(
      this.stage.width,
      this.stage.height,
      this.x.value * this.stage.width,
      this.y.value * this.stage.height,
      this.stage.width,
      this.stage.height,
    )
  }

  getTargets(state: State) {
    switch (state) {
      case 'planet': {
        return { theta: 0, phi: Math.PI * 0.5, radius: 4, x: 0, y: 0 }
      }
      case 'map': {
        return { theta: 0, phi: Math.PI * 0.2, radius: 5, x: 0, y: 0 }
      }
      case 'text': {
        return { theta: 0, phi: Math.PI * 0.5, radius: 3.5, x: 0, y: 0 }
      }
      case 'gallery': {
        return { theta: 0, phi: Math.PI * 0.5, radius: 1.1, x: 0, y: 0 }
      }
      case 'closeup': {
        const x = this.stage.mq.matches ? -0.3 : -0.9
        const y = this.stage.mq.matches ? 0.25 : 0.9
        return { theta: Math.PI, phi: Math.PI * 0.5, radius: 2, x, y }
      }
    }
  }

  setLocked(state: State) {
    switch (state) {
      case 'text':
      case 'gallery':
      case 'closeup': {
        this.locked = true
        break
      }
      default: {
        this.locked = false
      }
    }
  }

  setAutoRotate(state: State) {
    switch (state) {
      case 'planet':
      case 'map':
      case 'closeup': {
        this.autoRotate = true
        break
      }
      default: {
        this.autoRotate = false
      }
    }
  }

  navigate(state: State) {
    const { theta, phi, radius, x, y } = this.getTargets(state)

    const numRotations = Math.floor(this.theta.value / (Math.PI * 2))
    const nearestTheta = numRotations * Math.PI * 2 + theta

    this.theta.setTarget(nearestTheta)
    this.phi.setTarget(phi)
    this.radius.setTarget(radius)
    this.x.setTarget(x)
    this.y.setTarget(y)

    this.setLocked(state)
    this.setAutoRotate(state)
  }

  update(delta: number): void {
    const {
      controls: { pointerDelta, dragging },
    } = this.stage

    this.theta.update(delta)
    this.phi.update(delta)
    this.radius.update(delta)
    this.x.update(delta)
    this.y.update(delta)

    if (this.autoRotate) {
      this.theta.setTarget(this.theta.target + 0.001)
    }

    if (dragging && !this.locked) {
      const mapAmount = this.stage.planet?.mapAmount.value || 0
      const min = MathUtils.lerp(MIN_PHI, MAP_MIN_PHI, mapAmount)
      const max = MathUtils.lerp(MAX_PHI, MAP_MAX_PHI, mapAmount)
      const phi = MathUtils.clamp(this.phiStart - pointerDelta.y, min, max)

      this.theta.setValue(this.thetaStart - pointerDelta.x)
      this.phi.setValue(phi)
    }

    this.updateCamera()
  }
}
