import { MathUtils, Vector2 } from 'three'
import Stage from './Stage'
import { EventEmitter } from './EventEmitter'
import getDistance2d from './getDistance2d'

const FRICTION = 0.95

type Point = {
  x: number
  y: number
  timeStamp: number
}

export default class Controls extends EventEmitter {
  private readonly activePointers: PointerEvent[] = []
  private readonly pointer = new Vector2()
  private readonly pointerStart = new Vector2()

  private points: Point[] = []
  private needsUpdate = false
  private pointerDistanceStart = 0
  private pointerDistance = 0
  private zoomStart = 1

  public readonly normalizedPointer = new Vector2()
  public readonly pointerDelta = new Vector2()
  public readonly velocity = new Vector2()
  public zoom = 1

  public dragging = false

  constructor(private readonly stage: Stage) {
    super()

    this.onPointerStart = this.onPointerStart.bind(this)
    this.onPointerMove = this.onPointerMove.bind(this)
    this.onPointerEnd = this.onPointerEnd.bind(this)

    document.body.addEventListener('pointerdown', this.onPointerStart)
    document.body.addEventListener('pointermove', this.onPointerMove)
    document.body.addEventListener('pointerup', this.onPointerEnd)
    document.body.addEventListener('pointercancel', this.onPointerEnd)
    document.body.addEventListener('pointerleave', this.onPointerEnd)
    document.body.addEventListener('wheel', this.onWheel.bind(this))
  }

  onWheel(event: WheelEvent) {
    this.emit('wheel', event)
  }

  onPointerStart(event: PointerEvent): void {
    const { timeStamp } = event
    const { width, height } = this.stage
    const x = (event.x / width) * 2 - 1
    const y = (event.y / height) * 2 + 1

    this.activePointers.push(event)
    this.points = [{ x, y, timeStamp }]
    this.pointer.set(x, y)
    this.pointerStart.copy(this.pointer)
    this.velocity.set(0, 0)

    if (this.activePointers.length === 2) {
      this.pointerDistance = getDistance2d(this.activePointers[0], this.activePointers[1])
      this.pointerDistanceStart = this.pointerDistance
      this.zoomStart = this.zoom
    }
  }

  onPointerMove(event: PointerEvent): void {
    const { timeStamp } = event
    const { width, height } = this.stage
    const x = (event.x / width) * 2 - 1
    const y = (event.y / height) * 2 + 1

    this.pointer.set(x, y)

    for (let i = 0; i < this.activePointers.length; i++) {
      if (event.pointerId === this.activePointers[i].pointerId) {
        this.activePointers[i] = event
        break
      }
    }

    if (this.activePointers.length === 1) {
      this.pointerDelta.subVectors(this.pointer, this.pointerStart)
      const dragging = this.pointerDelta.length() > 0.02
      if (!this.dragging && dragging) {
        this.dragging = true
        this.emit('start-drag')
      }

      const time = performance.now()
      while (this.points.length) {
        if (time - this.points[0].timeStamp <= 100) {
          break
        }
        this.points.shift()
      }

      this.points.push({ x, y, timeStamp })
    } else if (this.activePointers.length === 2) {
      this.pointerDistance = getDistance2d(this.activePointers[0], this.activePointers[1])
      const factor = this.pointerDistance / this.pointerDistanceStart
      this.zoom = this.zoomStart * factor
      this.zoom = MathUtils.clamp(this.zoom, 0.5, 2)
    }

    this.needsUpdate = true
    this.emit('move')
  }

  onPointerEnd(event: PointerEvent): void {
    const { timeStamp } = event
    const { width, height } = this.stage
    const x = (event.x / width) * 2 - 1
    const y = (event.y / height) * 2 + 1

    const index = this.activePointers.findIndex(({ pointerId }) => pointerId === event.pointerId)
    if (!this.activePointers[index]) return //prevent multiple triggers if touch -> pointerup followed by pointerleave

    this.activePointers.splice(index, 1)

    if (this.activePointers.length === 0) {
      this.points.push({ x, y, timeStamp })

      const firstPoint = this.points.at(0)
      const lastPoint = this.points.at(-1)

      if (firstPoint && lastPoint) {
        const duration = lastPoint.timeStamp - firstPoint.timeStamp
        const velocityX = (lastPoint.x - firstPoint.x) / duration || 0
        const velocityY = (lastPoint.y - firstPoint.y) / duration || 0
        this.velocity.set(velocityX, velocityY)
      }

      if (this.dragging) {
        this.dragging = false
        this.emit('end-drag')
      }
    }
  }

  update(): void {
    if (this.needsUpdate) {
      this.needsUpdate = false
      this.normalizedPointer.x = MathUtils.clamp((this.pointer.x / this.stage.width) * 2 - 1, -1, 1)
      this.normalizedPointer.y = MathUtils.clamp(-(this.pointer.y / this.stage.height) * 2 + 1, -1, 1)
    }

    this.velocity.x *= FRICTION
    this.velocity.y *= FRICTION
  }
}
