import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Group,
  Points,
  ShaderMaterial,
  Texture,
} from 'three'
import vertexShader from './planet.vert'
import fragmentShader from './planet.frag'
import Stage, { SPRING_CONFIG } from './Stage'
import { Label } from './Label'
import { State } from './App'
import { Spring } from './Spring'

function shuffle(array: number[]): number[] {
  let currentIndex = array.length
  let randomIndex

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export const IMAGE_RESOLUTION = 128
export const MAP_SCALE = 2
export const MAP_ELEVATION_SCALE = 1.5
export const MAP_CENTER = [2634000.0, 1124000.0]
export const MIN_ALTITUDE = 633
export const MAX_ALTITUDE = 2912
export const TILE_SIZE = 12000

export const elevationFactor = ((MAX_ALTITUDE - MIN_ALTITUDE) / TILE_SIZE) * MAP_SCALE * MAP_ELEVATION_SCALE

export type Item = {
  label: string
  subline: string
  coordinates: [number, number, number]
  length: number
  showBox?: boolean
}

const Items: Item[] = [
  {
    label: 'VISP',
    subline: '[46.2947° N, 7.8821° E]',
    coordinates: [2634171.069, 1127128.266, 658],
    length: 0.1,
  },
  {
    label: 'STALDEN',
    subline: '[46.2331° N, 7.8707° E]',
    coordinates: [2633330.294, 1120275.691, 795],
    length: 0.1,
  },
  {
    label: 'KUNSTPLANET\nHOFLÜO 26\nCH–3930 VISP',
    subline: '[46.2728° N, 7.8823° E]',
    coordinates: [2634201.07, 1124694.466, 680],
    length: 0.4,
    showBox: true,
  },
]

export default class Planet {
  private readonly points: Points<BufferGeometry, ShaderMaterial>
  private readonly object = new Group()
  private readonly labels: Label[]
  private readonly labelGroup: Group
  private readonly dissolve: Spring
  private lastState?: State

  public readonly mapAmount: Spring

  constructor(
    private readonly stage: Stage,
    logoImage: HTMLImageElement,
    particleTexture: Texture,
    elevationTexture: Texture,
    state: State,
  ) {
    const { mapAmount, dissolve } = this.getTargets(state)
    this.mapAmount = new Spring(mapAmount, ...SPRING_CONFIG)
    this.dissolve = new Spring(dissolve, ...SPRING_CONFIG)
    this.lastState = state

    this.labelGroup = new Group()
    this.labelGroup.scale.multiplyScalar(MAP_SCALE)
    this.object.add(this.labelGroup)
    this.labels = Items.map((item, index) => new Label(stage, this.labelGroup, item, index, state))

    const canvas = document.createElement('canvas')
    canvas.width = IMAGE_RESOLUTION
    canvas.height = IMAGE_RESOLUTION
    const ctx = canvas.getContext('2d', { willReadFrequently: false })
    ctx?.drawImage(logoImage, 0, 0, IMAGE_RESOLUTION, IMAGE_RESOLUTION)
    const pixels: Uint8ClampedArray =
      ctx?.getImageData(0, 0, IMAGE_RESOLUTION, IMAGE_RESOLUTION).data || new Uint8ClampedArray()

    const positions: number[] = []
    const colorOffsets: number[] = []

    const phi = Math.PI * (Math.sqrt(5) - 1)

    const fibonacci = (index: number, count: number) => {
      const y = 1 - (index / (count - 1)) * 2
      const radius = Math.sqrt(1 - y * y)

      const theta = phi * index

      const x = Math.cos(theta) * radius
      const z = Math.sin(theta) * radius

      return { x, y, z }
    }

    //KP
    for (let i = 0; i < 100_000; i++) {
      const { x, y, z } = fibonacci(i, 100_000)

      const radius2 = Math.sqrt(x * x + y * y + z * z)
      const theta2 = Math.acos(y / radius2)
      const phi2 = Math.atan2(z, x)

      const u = 1 - (phi2 + Math.PI) / (2 * Math.PI)
      const v = theta2 / Math.PI

      const column = Math.round(u * IMAGE_RESOLUTION)
      const row = Math.round(v * IMAGE_RESOLUTION)

      const index = row * IMAGE_RESOLUTION + column
      const red = pixels[index * 4] || 0

      if (red > 128) {
        positions.push(x)
        positions.push(y)
        positions.push(z)
        colorOffsets.push(0.5 + Math.random() * 0.5)
      }
    }

    for (let i = 0; i < 100_000; i++) {
      const { x, y, z } = fibonacci(i, 100_000)
      const random = Math.random()
      const radius2 = Math.sqrt(x * x + y * y + z * z)
      const theta2 = Math.acos(y / radius2)
      const yOffset = Math.max(Math.abs((theta2 / Math.PI) * 2 - 1), 0.05)

      if (random < Math.abs(yOffset)) {
        positions.push(x)
        positions.push(y)
        positions.push(z)
        colorOffsets.push(0.25 + Math.random() * 0.5)
      }
    }

    const indices = shuffle([...new Array(Math.floor(positions.length / 3)).keys()])
    const resolution = Math.floor(Math.sqrt(indices.length))

    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3))
    geometry.setAttribute('index', new Float32BufferAttribute(indices, 1))
    geometry.setAttribute('colorOffset', new Float32BufferAttribute(colorOffsets, 1))

    const material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uScale: { value: this.stage.height * 0.5 },
        uPointScale: { value: this.stage.renderer.getPixelRatio() },
        uMapAmount: { value: this.mapAmount.value },
        uMapScale: { value: MAP_SCALE },
        uMapElevationFactor: { value: elevationFactor },
        uTime: { value: performance.now() },
        uTexture: { value: particleTexture },
        uElevation: { value: elevationTexture },
        uColor: { value: new Color() },
        uCameraDistance: { value: 0 },
        uResolution: { value: resolution },
        uDissolve: { value: this.dissolve.value },
      },
      blending: AdditiveBlending,
      transparent: true,
      depthTest: false,
    })

    this.points = new Points(geometry, material)

    this.object.add(this.points)
    this.stage.scene.add(this.object)
  }

  showMap() {
    this.labels.forEach((label) => label.show())
  }

  hideMap() {
    this.labels.forEach((label) => label.hide())
  }

  setColor(color: string) {
    this.points.material.uniforms.uColor.value.setStyle(color)
  }

  setAmount(amount: number) {
    this.points.material.uniforms.uAmount.value = amount
  }

  getTargets(state: State) {
    switch (state) {
      case 'map': {
        return { mapAmount: 1, dissolve: 0 }
      }
      case 'text': {
        if (this.lastState && this.lastState === 'map') {
          return { mapAmount: 1, dissolve: 1 }
        }
        return { mapAmount: 0, dissolve: 1 }
      }
      case 'gallery': {
        return { mapAmount: 0, dissolve: 1 }
      }
      default: {
        return {
          mapAmount: 0,
          dissolve: 0,
        }
      }
    }
  }

  navigate(state: State) {
    const { mapAmount, dissolve } = this.getTargets(state)
    this.mapAmount.setTarget(mapAmount)
    this.dissolve.setTarget(dissolve)
    state === 'map' ? this.showMap() : this.hideMap()
    this.lastState = state
  }

  resize() {
    this.points.material.uniforms.uScale.value = this.stage.height * 0.5
  }

  update(time: number, delta: number) {
    this.dissolve.update(delta)
    this.mapAmount.update(delta)

    this.object.visible = this.dissolve.value < 1
    if (!this.object.visible) return

    this.points.material.uniforms.uTime.value = time
    this.points.material.uniforms.uDissolve.value = this.dissolve.value
    this.points.material.uniforms.uMapAmount.value = this.mapAmount.value
    this.points.material.uniforms.uCameraDistance.value = this.stage.camera.position.distanceTo(this.points.position)
    this.labels.forEach((label) => label.update(time))
  }
}
